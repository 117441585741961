import React, { useState } from 'react'
import { useInlineMemoizedContentGetter, useMemoizedContentGetter } from 'utils/contentful'
import { Typography, MediaImageStandard } from 'components/Contentful'
import { CardMedia as AscendCardMedia, Box, Grid } from '@achieve/ascend'
import Section from 'components/Section'
import SectionContainer from 'components/Section/SectionContainer'
import { useViewportSmallerThan } from 'utils/viewport'
import { BREAKPOINTS } from 'constants/viewport'
import { Eyebrow } from 'components/Eyebrow'
import { VideoPlayer } from 'components/VideoPlayer'
import { SectionAccordion } from 'components/SectionAccordion.js'
import { StickyTabs } from 'components/StickyTabs'
import classNames from 'classnames'

function DebtConsolidationSolutions({ content }) {
  // Initialize with the first section open
  const [selectedSection, setSelectedSection] = useState(0)

  // eslint-disable-next-line no-unused-vars
  const {
    jsonContent: {
      prosBoxTitle,
      consBoxTitle,
      consBoxTitleColor,
      prosBoxTitleColor,
      boxBorderNone,
      boxMargin,
      stickyTabsLabels = [],
      collapsible,
      style,
    } = {},
  } = useInlineMemoizedContentGetter(content?.fields?.additionalConfiguration, ['jsonContent'])

  const handleAccordionChange = (index) => {
    // Toggle the selected section. If the section is already open, close it, otherwise open it.
    setSelectedSection(selectedSection === index ? null : index)
  }

  // Utility function to render the accordion content based on the section type
  const renderSectionContent = (sectionType, grid, idx) => {
    const notLast = !collapsible && idx != sectionElements.length - 1
    switch (sectionType) {
      case 'textSection':
        return renderTextSection(grid, notLast)
      case 'videoSection':
        return renderVideoSection(grid, notLast)
      case 'cardSection':
        return renderCardSection(grid, notLast)
      case 'linkedSection':
        return renderLinkedSection(grid, notLast)
      case 'linkedIconSection':
        return renderLinkedIconSection(grid, notLast)
      case 'boxSection':
        return renderBoxSection(grid, notLast)
      default:
        return null
    }
  }
  const sectionElements = content?.fields?.sectionContents?.filter((section) => {
    return [
      'textSection',
      'videoSection',
      'cardSection',
      'linkedSection',
      'linkedIconSection',
      'boxSection',
    ].includes(section?.fields?.fieldName)
  })

  const { eyebrow } = useMemoizedContentGetter(content, ['eyebrow'])

  let isMobile = useViewportSmallerThan(BREAKPOINTS.lg)

  const HairlineDivider = () => {
    return <hr className={'border-t-[#c0cbd8] border-t border-solid'} />
  }

  const colorMap = {
    grey: 'text-[#5c708a]',
  }

  const TableMaker = ({ box, pros, cons }) => {
    return (
      <div
        className={
          'w-full [&>div]:overflow-hidden [&>div]:[&>th]:w-full [&>div]:[&>tr]:w-full lg:flex lg:w-[45%] lg:justify-around'
        }
      >
        <div className={'w-full'}>
          {box?.gridItemsCollection?.items?.map((pro, id) => {
            if (id === 0) {
              return (
                <div
                  key={id}
                  className={classNames(
                    pros
                      ? boxBorderNone
                        ? 'bg-[#eff2f8] h-[52px] flex items-center rounded-[10px_10px_0px_0px] w-full pl-4 border-b-0 lg:h-[52px] lg:flex lg:items-center lg:border lg:rounded-[10px_10px_0px_0px] lg:w-full lg:pl-4 lg:border-b-0 lg:border-solid lg:border-[#c0cbd8]'
                        : 'bg-[#eff2f8] h-[52px] flex items-center border rounded-[10px_10px_0_0] w-full pl-4 border-b-0 border-solid border-[#c0cbd8] lg:h-[52px] lg:flex lg:items-center lg:border lg:rounded-[10px_10px_0px_0px] lg:w-full lg:pl-4 lg:border-b-0 lg:border-solid lg:border-[#c0cbd8]'
                      : boxMargin
                      ? boxBorderNone
                        ? 'bg-[#eff2f8] h-[52px] flex items-center rounded-[10px_10px_0px_0px] w-full pl-4 border-b-0 lg:h-[52px] lg:flex lg:items-center lg:border lg:rounded-[10px_10px_0px_0px] lg:w-full lg:pl-4 lg:border-b-0 lg:border-solid lg:border-[#c0cbd8]'
                        : 'bg-[#eff2f8] h-[52px] flex items-center border rounded-[10px_10px_0_0] w-full pl-4 border-b-0 border-solid border-[#c0cbd8] lg:h-[52px] lg:flex lg:items-center lg:border lg:rounded-[10px_10px_0px_0px] lg:w-full lg:pl-4 lg:border-b-0 lg:border-solid lg:border-[#c0cbd8]'
                      : boxBorderNone
                      ? 'bg-[#eff2f8] h-[52px] flex items-center  w-full pl-4 border-b-0 border-t-0 lg:flex lg:items-center lg:border lg:rounded-[10px_10px_0px_0px] lg:w-full lg:pl-4 lg:border-b-0 lg:border-solid lg:border-[#c0cbd8]'
                      : 'bg-[#eff2f8] h-[52px] flex items-center  border border-solid border-[#c0cbd8] w-full pl-4 border-b-0 border-t-0  lg:border lg:rounded-[10px_10px_0px_0px] lg:w-full lg:pl-4 lg:border-b-0 lg:border-solid lg:border-[#c0cbd8]'
                  )}
                >
                  <AscendCardMedia
                    data-testid="ascend-card-media"
                    className={'flex items-center mr-4'}
                  >
                    <MediaImageStandard
                      data-testid="card-media-image"
                      alt={box?.gridItemsCollection?.items?.[0]?.mediaAltText || 'table-icon-image'}
                      layout="fixed"
                      width={30}
                      height={30}
                      content={box.gridItemsCollection.items[0].mediaContent}
                    />
                  </AscendCardMedia>
                  <Typography
                    className={classNames(
                      pros
                        ? colorMap[prosBoxTitleColor] ?? 'text-[#1d252f]'
                        : colorMap[consBoxTitleColor] ?? 'text-[#1d252f]'
                    )}
                    fontWeight="medium"
                    variant="bodySm"
                    content={pros ? prosBoxTitle ?? 'Pros' : consBoxTitle ?? 'Cons'}
                  />
                </div>
              )
            } else if (id !== 0) {
              return (
                <div
                  key={id}
                  className={classNames(
                    (box.gridItemsCollection.items.length - 1 === id && !isMobile) ||
                      (box.gridItemsCollection.items.length - 1 === id && !pros) ||
                      (box.gridItemsCollection.items.length - 1 === id && !cons) ||
                      (box.gridItemsCollection.items.length - 1 === id && boxMargin)
                      ? boxBorderNone
                        ? 'rounded-[0_0_10px_10px] border-t-0 mb-8 p-4 bg-white lg:rounded-[0_0_10px_10px] lg:p-4 lg:border-b-[#c0cbd8] lg:border-x-[#c0cbd8] lg:border-b lg:border-solid lg:border-r lg:border-l lg:bg-white'
                        : 'rounded-[0_0_10px_10px] border-t-0 mb-4 p-4 border-b-[#c0cbd8] border-x-[#c0cbd8] border-b border-solid border-r border-l bg-white lg:rounded-[0_0_10px_10px] lg:p-4 lg:border-b-[#c0cbd8] lg:border-x-[#c0cbd8] lg:border-b lg:border-solid lg:border-r lg:border-l lg:bg-white'
                      : boxBorderNone
                      ? 'bg-white border-b-blue-50 p-4 border-b-2 border-solid border-t-0 lg:border-x-[#c0cbd8] lg:border-r lg:border-solid lg:border-l'
                      : 'bg-white border-b-blue-50 border-t-0 p-4 border-b-2 border-x-[#c0cbd8] border-solid border-r border-l lg:border-x-[#c0cbd8] lg:border-r lg:border-solid lg:border-l'
                  )}
                >
                  <Typography fontWeight="medium" variant="bodySm" content={pro?.textContent} />
                </div>
              )
            }
          })}
        </div>
      </div>
    )
  }

  const extractProperty = (grid, property) => {
    return grid?.filter((element) => {
      return element?.fieldName == property
    })[0]
  }

  const renderSection = (element, idx) => {
    const sectionType = element?.fields?.fieldName
    const grid = element?.fields?.gridItemsCollection?.items
    const title = extractProperty(grid, 'title')?.textContent?.json?.content?.[0]?.content[0]?.value
    const sectionContent = renderSectionContent(sectionType, grid, idx)

    return {
      element: collapsible ? (
        <SectionAccordion
          key={idx}
          title={title}
          section={sectionContent}
          sectionNumber={idx}
          selected={selectedSection === idx}
          onChangeExpanded={() => handleAccordionChange(idx)}
          index={idx}
        />
      ) : (
        sectionContent
      ),
      title: title,
    }
  }

  const SectionTitle = ({ title }) => {
    return (
      <Typography
        content={title}
        fontWeight="medium"
        variant={isMobile ? 'headingMd' : 'displayMd'}
        className={'my-8 mx-o'}
      />
    )
  }

  const renderVideoSection = (grid) => {
    const videoTitle = extractProperty(grid, 'title')
    const videoPlayer = extractProperty(grid, 'videoPlayer')
    if (!videoTitle) return null
    return (
      <SectionContainer className={''} narrow={!collapsible}>
        {!collapsible && videoTitle?.textContent && <SectionTitle title={videoTitle.textContent} />}
        <div className={'relative max-h-[content] overflow-hidden'}>
          <VideoPlayer content={videoPlayer} aspectRatio={16 / 9} extraPadding={120} />
        </div>
      </SectionContainer>
    )
  }

  const renderTextSection = (grid, notLast) => {
    const title = extractProperty(grid, 'title')
    const text = extractProperty(grid, 'text')
    if (!title) return null
    return (
      <SectionContainer narrow={!collapsible}>
        {!collapsible && title?.textContent && <SectionTitle title={title.textContent} />}
        <Typography content={text?.textContent} variant={'bodySm'} className={'mb-8'} />
        {notLast && <HairlineDivider />}
      </SectionContainer>
    )
  }

  const renderCardSection = (grid, notLast) => {
    const title = extractProperty(grid, 'title')
    const text = extractProperty(grid, 'text')
    const gridContent = extractProperty(grid, 'grid')
    if (!text || !title || !gridContent) return null
    return (
      <SectionContainer narrow={!collapsible}>
        {!collapsible && title?.textContent && <SectionTitle title={title.textContent} />}
        <Typography content={text?.textContent} variant={'bodySm'} />
        {gridContent?.gridItemsCollection?.items?.map((card, id) => {
          return (
            <React.Fragment key={id}>
              <Box
                className={
                  'flex bg-white border mx-0 my-8 p-2 rounded-[8px] border-solid border-[#c0cbd8]'
                }
              >
                {card?.cardBadgeImage && (
                  <AscendCardMedia data-testid="ascend-card-media" className={'my-0 mx-4'}>
                    <MediaImageStandard
                      data-testid="card-media-image"
                      layout="fixed"
                      width={42}
                      height={42}
                      content={card.cardBadgeImage}
                      alt={card?.title || 'card-badge-image'}
                    />
                  </AscendCardMedia>
                )}

                <Typography
                  data-testid="card-subtitle"
                  variant={'bodySm'}
                  component="p"
                  content={card.cardSubtitleText}
                />
              </Box>
            </React.Fragment>
          )
        })}
        {notLast && <HairlineDivider />}
      </SectionContainer>
    )
  }

  const renderLinkedSection = (grid, notLast) => {
    const title = extractProperty(grid, 'title')
    const text = extractProperty(grid, 'text')
    const gridContent = extractProperty(grid, 'grid')
    if (!text || !title || !gridContent) return null
    return (
      <SectionContainer narrow={!collapsible}>
        {!collapsible && title?.textContent && <SectionTitle title={title.textContent} />}
        <Typography content={text?.textContent} variant={'bodySm'} className={'my-8 mx-0'} />
        <ul className={'p-0'}>
          {gridContent?.gridItemsCollection?.items?.map((card, id) => {
            let intermediateObject = false

            if (card?.cardTitleText && card?.cardSubtitleText) {
              let text = [
                card?.cardTitleText?.json?.content[0].content[0],
                {
                  nodeType: 'text',
                  value: ' ',
                  marks: [{ type: 'bold' }],
                  data: {},
                },
                card?.cardSubtitleText?.json?.content[0].content[0],
              ]
              //prevents creating just a reference.
              intermediateObject = JSON.parse(JSON.stringify(card?.cardTitleText))
              intermediateObject.json.content[0].content = text
            }

            return (
              <li key={id} className={'list-none'}>
                <Box style={{ display: 'flex' }}>
                  <Box className={'pr-8 [&_img]:z-[1]'}>
                    {card?.cardBadgeImage && (
                      <AscendCardMedia
                        data-testid="ascend-card-media"
                        className={
                          id === 0
                            ? 'flex list-none relative h-full items-start p-0 before:absolute before:content-["_"] before:w-[3px] before:bg-[#3300ff] before:ml-[-1.5px] before:left-2/4 before:inset-y-0 [&>li]:before:table [&>li]:before:content-["_"] [&>li]:after:table [&>li]:after:content-["_"]'
                            : id === gridContent?.gridItemsCollection?.items.length - 1
                            ? 'flex list-none relative h-full items-start p-0 before:absolute before:content-["_"] before:w-[3px] before:bg-[#3300ff] before:ml-[-1.5px] before:left-2/4 before:top-0 before:bottom-full [&>li]:before:table [&>li]:before:content-["_"] [&>li]:after:table [&>li]:after:content-["_"]'
                            : 'flex list-none relative h-full items-start p-0 before:absolute before:content-["_"] before:w-[3px] before:bg-[#3300ff] before:ml-[-1.5px] before:left-2/4 before:inset-y-0 [&>li]:after:clear-both [&>li]:before:table [&>li]:before:content-["_"] [&>li]:after:table [&>li]:after:content-["_"]'
                        }
                      >
                        <MediaImageStandard
                          data-testid="card-media-image"
                          alt={card?.title || 'card-badge-image'}
                          layout="fixed"
                          width={40}
                          height={40}
                          content={card.cardBadgeImage}
                        />
                      </AscendCardMedia>
                    )}
                  </Box>
                  <Box className={'pb-8'}>
                    <Typography
                      data-testid="card-subtitle"
                      variant={'bodySm'}
                      component="p"
                      content={
                        isMobile
                          ? card?.cardTitleText
                          : intermediateObject
                          ? intermediateObject
                          : card?.cardTitleText
                      }
                    />
                    <Typography content={' '} />
                    {isMobile && (
                      <Typography
                        data-testid="card-subtitle"
                        variant={'bodySm'}
                        component="p"
                        content={card?.cardSubtitleText}
                      />
                    )}
                  </Box>
                </Box>
              </li>
            )
          })}
        </ul>
        {notLast && <HairlineDivider />}
      </SectionContainer>
    )
  }

  const renderLinkedIconSection = (grid, notLast) => {
    const title = extractProperty(grid, 'title')
    const text = extractProperty(grid, 'text')
    const gridContent = extractProperty(grid, 'grid')
    if (!text || !title || !gridContent) return null
    return (
      <SectionContainer narrow={!collapsible}>
        {!collapsible && title?.textContent && <SectionTitle title={title.textContent} />}
        <Typography content={text?.textContent} variant={'bodySm'} className={'mb-8'} />
        {gridContent?.gridItemsCollection?.items?.map((card, id) => {
          return (
            <React.Fragment key={id}>
              <Box style={{ display: 'flex' }}>
                <Box className={'pr-8 [&_img]:z-[1]'}>
                  {card?.cardBadgeImage && (
                    <AscendCardMedia
                      data-testid="ascend-card-media"
                      className={
                        id === 0
                          ? 'flex list-none relative h-full items-start p-0 before:absolute before:content-["_"] before:w-[3px] before:bg-[#3300ff] before:ml-[-1.5px] before:left-2/4 before:inset-y-0 [&>li]:before:table [&>li]:before:content-["_"] [&>li]:after:table [&>li]:after:content-["_"]'
                          : id === gridContent?.gridItemsCollection?.items.length - 1
                          ? 'flex list-none relative h-full items-start p-0 before:absolute before:content-["_"] before:w-[3px] before:bg-[#3300ff] before:ml-[-1.5px] before:left-2/4 before:top-0 before:bottom-full [&>li]:before:table [&>li]:before:content-["_"] [&>li]:after:table [&>li]:after:content-["_"]'
                          : 'flex list-none relative h-full items-start p-0 before:absolute before:content-["_"] before:w-[3px] before:bg-[#3300ff] before:ml-[-1.5px] before:left-2/4 before:inset-y-0 [&>li]:after:clear-both [&>li]:before:table [&>li]:before:content-["_"] [&>li]:after:table [&>li]:after:content-["_"]'
                      }
                    >
                      <MediaImageStandard
                        data-testid="card-media-image"
                        layout="fixed"
                        alt={card?.title || 'card-badge-image-secondary-icon'}
                        width={40}
                        height={40}
                        content={card.cardBadgeImage}
                      />
                    </AscendCardMedia>
                  )}
                </Box>
                <Box className={'flex flex-col items-start mb-8'}>
                  {card?.cardImage && (
                    <AscendCardMedia data-testid="ascend-card-media">
                      <MediaImageStandard
                        data-testid="card-media-image"
                        alt={card?.title || 'card-badge-image-alignment-icon'}
                        layout="fixed"
                        width={40}
                        height={40}
                        content={card.cardImage}
                      />
                    </AscendCardMedia>
                  )}
                  <Typography
                    data-testid="card-subtitle"
                    variant={'bodySm'}
                    component="p"
                    fontWeight="medium"
                    content={card?.cardTitleText}
                  />
                  <Typography
                    data-testid="card-subtitle"
                    variant={'bodySm'}
                    component="p"
                    content={card?.cardSubtitleText}
                  />
                </Box>
              </Box>
            </React.Fragment>
          )
        })}
        {notLast && <HairlineDivider />}
      </SectionContainer>
    )
  }

  const renderBoxSection = (grid, notLast) => {
    const title = extractProperty(grid, 'title')
    const text = extractProperty(grid, 'text')
    const prosBox = extractProperty(grid, 'prosBox')
    const consBox = extractProperty(grid, 'consBox')
    if (!title || !(!prosBox && !consBox))
      //Returns false if no prosBox and no consBox or no title
      return (
        <SectionContainer narrow={!collapsible}>
          {!collapsible && title?.textContent && <SectionTitle title={title.textContent} />}
          <Typography content={text?.textContent} variant={'bodySm'} />
          <Grid
            container
            className={
              'flex justify-around content-center flex-col mt-8 lg:flex lg:justify-between lg:content-center lg:flex-row lg:mt-8'
            }
          >
            {prosBox && <TableMaker box={prosBox} pros={true} cons={!!consBox} />}
            {consBox && <TableMaker box={consBox} />}
          </Grid>
          {notLast && <HairlineDivider />}
        </SectionContainer>
      )
  }

  const sections = sectionElements.map(renderSection)

  // TODO: Implement sticky tabs for collapsible SectionAccordion component
  let stickyTabsContent = []
  let labelCounter = 0
  for (let i = 0; i < sections.length; i++) {
    if (sections[i].title) {
      stickyTabsContent.push({
        tabLabel:
          stickyTabsLabels[labelCounter] ??
          sections[i].title?.textContent?.content[0]?.content[0]?.value,
        tabItem: sections[i].element,
      })
      labelCounter++
    }
  }

  return (
    <Section className={'bg-[#eff5ff] px-0 py-12'} sectionStyles={style}>
      <Grid alignItems="center" columnSpacing={{ xs: 1, lg: 3 }} container justifyContent="center">
        <Grid item xs={collapsible ? 11 : 12}>
          {eyebrow?.textContent && <Eyebrow content={eyebrow.textContent} component="h2" black />}
        </Grid>
        <Grid item xs={collapsible ? 11 : 12} lg={collapsible ? 9 : 12}>
          {(collapsible || !isMobile) && sections.map((section) => section.element)}
        </Grid>

        {!collapsible && isMobile && (
          <StickyTabs className={''} isMobile={true} stickyTabsContent={stickyTabsContent} />
        )}
      </Grid>
    </Section>
  )
}

export default DebtConsolidationSolutions
